<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="registry-wrapper">
        <BillingTop />
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="billing"
            role="tabpanel"
            aria-labelledby="billing-tab"
          >
            <Billing />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import Billing from "../components/billing/Billing.vue";
import BillingTop from "../components/billing/BillingTop.vue";

export default {
  name: "Bill",
  components: {
    LeftMenu,
    Navbar,
    BillingTop,
    Billing,
  },
};
</script>

<style></style>

<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#AddPaymentModal"
      ref="openUpdateCardModal"
    ></button>
    <div
      class="modal fade"
      id="AddPaymentModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <p>{{ modalTitle }}</p>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalAddCategory"
              @click="clearForm()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="registry-sign-up-form">
                <div class="company-info-wrapper">
                  <div class="container-fluid p-0">
                    <div class="row">
                      <div class="col-12 p-0">
                        <div class="form-group">
                          <label for="customer_name"
                            >Full Name <span class="astrick">*</span></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="full_name"
                            placeholder="Enter Full Name"
                            autocomplete="off"
                            v-model.trim="$v.details.fullname.$model"
                            :class="{
                              'is-invalid': $v.details.fullname.$error,
                              'is-valid': !$v.details.fullname.$invalid,
                            }"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.fullname.required"
                              >Full name is required</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0">
                        <div class="form-group">
                          <label for="contact_person"
                            >Email <span class="astrick">*</span>
                          </label>
                          <input
                            type="email"
                            v-model.trim="$v.details.email.$model"
                            class="form-control"
                            id="email"
                            placeholder="Enter Email"
                            :class="{
                              'is-invalid': $v.details.email.$error,
                              'is-valid': !$v.details.email.$invalid,
                            }"
                            autocomplete="off"
                          />
                          <div class="invalid-tooltip">
                            <span v-if="!$v.details.email.required"
                              >Email is required</span
                            >
                            <span v-if="!$v.details.email.email"
                              >Email must be valid
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-0">
                        <section class="row payment-form">
                          <div
                            v-if="stripeValidationError != null"
                            class="error red center-align white-text"
                          >
                            {{ stripeValidationError }}
                          </div>
                          <div v-else></div>

                          <div class="col-12 card-element form-group p-0">
                            <label>Card Number</label>
                            <div
                              id="card-number-element1"
                              class="input-value"
                            ></div>
                          </div>
                          <div
                            class="col-12 col-lg-6 card-element form-group p-0"
                          >
                            <label>Expiry Date</label>
                            <div id="card-expiry-element1"></div>
                          </div>

                          <div
                            class="col-12 col-lg-6 card-element form-group p-0 pl-lg-2"
                          >
                            <label>CVC</label>
                            <div id="card-cvc-element1"></div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                class="custom-btn2"
                @click="createPayment(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>{{ modalBtn }}</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import { required, email } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "UpdateCard",
  data: function() {
    return {
      details: {
        fullname: "",
        email: "",
      },
      old_card_id: "",
      stripe: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      isLoadingArray: [],
      stripeValidationError: null,
      modalBtn: "",
      modalTitle: "",
    };
  },
  validations: {
    details: {
      fullname: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    clearForm() {
      this.details.fullname = "";
      this.details.email = "";
    },
    createPayment: function(index) {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);

      this.stripe.createToken(this.cardNumberElement).then((result) => {
        if (result.error) {
          this.stripeValidationError = result.error.message;
        } else {
          var stripeObject = {
            source: result.token,
            old_card_id: this.old_card_id,
            name: this.details.fullname,
          };
          if (this.old_card_id != null) {
            this.updateCard(stripeObject);
          } else {
            this.addNewCard(stripeObject);
          }
        }
        this.$set(this.isLoadingArray, index, false);
      });
    },
    createAndMountFormElements() {
      var elements = this.stripe.elements();
      this.cardNumberElement = elements.create("cardNumber");
      this.cardNumberElement.mount("#card-number-element1");

      this.cardExpiryElement = elements.create("cardExpiry");
      this.cardExpiryElement.mount("#card-expiry-element1");

      this.cardCvcElement = elements.create("cardCvc");
      this.cardCvcElement.mount("#card-cvc-element1");
    },
    updateCard(stripeObject) {
      axios
        .post(process.env.VUE_APP_API_URL + "payment/editCard", stripeObject)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$swal(
              "Card Updated!",
              "Your card has been updated successfully.",
              "success"
            );
            var elem = this.$refs.closeModalAddCategory;
            if (elem) {
              this.$root.$emit("autoUpdate");
              elem.click();
              this.clearForm();
            }
          } else {
            this.$swal("Somthing Wrong!", response.data.message, "Error");
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.$swal(
              "Somthing Wrong!",
              "Your card is not updated please contact the admin or try again.",
              "Error"
            );
          }
        });
    },
    addNewCard(stripeObject) {
      axios
        .post(process.env.VUE_APP_API_URL + "payment/addCard", stripeObject)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$swal(
              "Card Added!",
              "Your card has been added successfully.",
              "success"
            );
            var elem = this.$refs.closeModalAddCategory;
            if (elem) {
              this.$root.$emit("autoUpdate");
              elem.click();
              this.clearForm();
            }
          } else {
            this.$swal("Somthing Wrong!", response.data.message, "Error");
            console.log(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.$swal(
              "Somthing Wrong!",
              "Your card is not updated please contact the admin or try again.",
              "Error"
            );
          }
        });
    },
  },
  mounted() {
    this.$root.$on("openUpdateCardModal", (Card, modalTitle, modalBtn) => {
      var elem = this.$refs.openUpdateCardModal;
      if (elem) {
        elem.click();
      }
      if (Card != null) {
        this.old_card_id = Card.id;
      } else {
        this.old_card_id = Card;
      }
      this.modalTitle = modalTitle;
      this.modalBtn = modalBtn;
      this.stripe = Stripe(
        this.$stripePublicKey
      );
      this.createAndMountFormElements();
    });
  },
};
</script>

<style scoped>
label,
input,
input::placeholder,
input.form-control:focus {
  color: #383838;
}
input.form-control{
  background-color:transparent!important;
  border: 1px solid var(--light-gray);
}


input {
  padding-left: 10px;
  font-weight: 400;
}

#card-number-element1,
#card-expiry-element1,
#card-cvc-element1 {
  background: white;
  padding: 15px 10px 0 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 45px !important;
  line-height: 45px !important;
}
.modal-header {
  color: #4d4d4d;
}
</style>

<template>
  <div>
    <UpdateCard />
    <div class="row" v-if="user.renewalData != null">
      <div class="col-12 col-md-12 p-0">
        <div class="packages">
          <Packs />
        </div>
      </div>
      <!-- <div class="col-12 col-md-6 p-0 pl-md-2">
        <Renewal />
      </div> -->
    </div>
    <div class="row" v-else>
      <div class="col-12 col-md-12 p-0">
        <div class="packages">
          <Packs :packages="packages" />
        </div>
      </div>
      <!-- <div class="col-12 col-md-6 p-0 pl-md-2">
        <Renewal />
      </div> -->
    </div>
    <div class="transaction-wrapper">
      <div class="payment-method">
        <div class="payment-method-heading">
          <h4>Payment Method</h4>
          <button
            class="custom-btn4"
            @click="callUpdateModal(null, 'Add New Card', 'Add Card')"
          >
            Add New Card
          </button>
        </div>
        <div
          v-if="showLoader == 1"
          class="lds-dual-ring-registry"
          id="loading"
        ></div>
        <div
          class="row"
          v-if="cardData.data && cardData.data.length > 0 && showLoader == 0"
        >
          <div
            class="col-12 col-lg-6 col-xl-4 px-0 pr-lg-2 mb-2"
            v-for="card in cardData.data"
            :key="card.id"
          >
            <div class="card-content">
              <div class="card-wrapper">
                <div class="img" v-if="card.brand === 'Visa'">
                  <img src="../../assets/images/visa.png" alt="" />
                </div>
                <div class="img" v-else-if="card.brand === 'MasterCard'">
                  <img src="../../assets/images/mastercard.png" alt="" />
                </div>
                <div class="img" v-else>
                  <p>{{ card.brand }}</p>
                </div>
                <div class="card-number">
                  <span>****</span>
                  <span>****</span>
                  <span>****</span>
                  <span>{{ card.last4 }}</span>
                </div>
                <div class="card-actions">
                  <i
                    class="fa fa-pencil-alt"
                    @click="callUpdateModal(card, 'Update Card', 'Update')"
                  ></i>
                  <i class="fa fa-times" @click="deleteCard(card)"></i>
                </div>
              </div>
              <div class="holder-info">
                <h6>{{ card.name }}</h6>
                <span>Exp {{ card.exp_month }}/{{ card.exp_year }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <p class="text-center">No Card(s) added</p>
        </div>
      </div>
      <div class="col-12 transaction-details" v-if="transactionData">
        <div class="transaction-top">
          <h4>Transactions</h4>
          <button
            v-if="transactionData.totalItems > 0 && this.totalItems > this.size"
            type="button"
            @click="getData(3)"
          >
            VIEW ALL
          </button>
        </div>
        <div
          v-if="showLoader == 1"
          class="lds-dual-ring-registry"
          id="loading"
        ></div>
        <div v-if="transactionData.totalItems > 0 && showLoader == 0">
          <div
            class="transaction-content"
            v-for="transaction in transactionData.listing"
            :key="transaction.id"
          >
            <div class="transaction-data">
              <div class="date-number">
                <span class="date">{{
                  transaction.createdAt | moment("MMM D, YYYY")
                }}</span>
                <br />
                <span class="number">#{{ transaction.transaction_id }}</span>
              </div>
              <div class="price-save">
                <span class="price">${{ transaction.discounted_amount }}</span>
                <button
                  type="button"
                  class="save"
                  @click="getContent(transaction.charge_id)">          
                  <div v-if="pdfLoader == 1" class="lds-dual-ring-btn" id="loading"></div>
                  <span v-else>PDF</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>No Transaction(s) Found</div>
      </div>
    </div>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="mobilePreview"
      :paginate-elements-by-height="1400"
      filename="hehe"
      :pdf-quality="2"
      :manual-pagination="false"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="pdf-wrapper">
        <div class="pdf-header">
          <div class="left-top">
            <img src="../../assets/images/Left-top.png" alt="" />
          </div>
          <div class="center-top">
            <img src="../../assets/images/Icon-empty.png" alt="" />
          </div>
          <div class="right-top">
            <img src="../../assets/images/Right-top.png" alt="" />
          </div>
        </div>
        <div class="pdf-top">
          <h6>Receipt from GLOBALNDA</h6>
        </div>
        <div class="pdf-content">
          <div>
            <h6>AMOUNT PAID</h6>
            <p>${{ org_amount }}</p>
          </div>
          <div>
            <h6>DATE PAID</h6>
            <span>{{ receiptData.created | moment("MMM D, YYYY") }}</span>
          </div>
          <div v-for="card in cardData.data" :key="card.id">
            <h6>PAYMENT METHOD</h6>
            <div class="card-info">
              <div class="img" v-if="card.brand === 'Visa'">
                <img src="../../assets/images/visa.png" alt="" />
              </div>
              <div class="img" v-else-if="card.brand === 'MasterCard'">
                <img src="../../assets/images/mastercard.png" alt="" />
              </div>
              <div class="img" v-else>
                <p>{{ card.brand }}</p>
              </div>
              <div class="card-number">
                <span>- {{ card.last4 }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pdf-summary">
          <h6>SUMMARY</h6>
          <div class="summary-wrapper">
            <div class="summary-plan">
              <span>{{ receiptData.description }}</span>
              <span>${{ org_amount }}</span>
            </div>
            <div class="summary-amount">
              <span>Amount Charged</span>
              <span>${{ org_amount }}</span>
            </div>
          </div>
        </div>

        <div class="pdf-bottom">
          <p>If you have any questions, contact us at</p>
          <a href="hamilton@crushcontracts.com">hamilton@crushcontracts.com</a>
        </div>

        <div class="pdf-footer">
          <p>
            Something wrong with the email?
            <a href="#">View it in your browser</a>
          </p>
          <p>
            You're receiving this email because you made a purchase at
            GlobalNDA, which partners with <strong>Stripe</strong> to provide
            invoicing and payment processing.
          </p>
        </div>
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import axios from "axios";
import UpdateCard from "../packages/updateCardCustomer.vue";
import Packs from "../packages/Packs.vue";
// import Renewal from "../packages/Renewal.vue";
import { mapGetters } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
export default {
  name: "Billing",
  components: { UpdateCard, Packs, 
    // Renewal, 
    VueHtml2pdf },
  data: function() {
    return {
      transactionData: "",
      cardData: {},
      packages: [],
      size: 3,
      showLoader: 0,
      pdfLoader: 0,
      totalItems: 3,
      mobilePreview: true,
      receiptData: "",
      amount: "",
      org_amount: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    htmlToPdfOptions() {
      return {
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        html2canvas: {
          useCORS: true,
        },
      };
    },
  },
  methods: {
    //get transactions and stripe card
    getData(plus) {
      //api call

      if (this.totalItems >= this.size) {
        this.size = this.size + plus;
        this.showLoader = 1;
        axios
          .post(
            process.env.VUE_APP_API_URL +
              `payment/viewTransaction?size=${this.size}`
          )
          .then((response) => {
            if (response.data.statusCode === 200) {
              this.transactionData = response.data.data;
              this.cardData = response.data.card;
              this.totalItems = response.data.data.totalItems;
              this.showLoader = 0;
            } else {
              alert("Something went wrong");
              this.showLoader = 0;
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              alert("Something went wrong");
            }
          });
      }
    },
    //call modal to update and add new card
    callUpdateModal: function(card, modalTitle, modalBtn) {
      this.$root.$emit("openUpdateCardModal", card, modalTitle, modalBtn);
    },
    //get transaction details
    getPacksData() {
      this.showLoader = 1;
      axios
        .post(`${process.env.VUE_APP_API_URL}package/listing`, {})
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.packages = response.data.data;
            this.showLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.showLoader = 0;
          }
        });
    },
    getContent(charge_id) {
      this.pdfLoader = 1;
      var formData = new FormData();
      formData.append("charge_id", charge_id);
      axios
        .post(
          `${process.env.VUE_APP_API_URL}payment/viewPDFTransaction`,
          formData
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.receiptData = response.data.transaction;
            this.amount = response.data.transaction.amount;
            this.org_amount = (this.amount / 100).toFixed(2);
            this.$refs.html2Pdf.generatePdf();
          } else {
            this.errorMessage = response.data.message;
          }
          this.pdfLoader=0;
        });
    },
    //delete card api
    deleteCard(card) {
      this.$swal({
        title: "Are you sure you want to delete your card?",
        text: "You can’t undo this action.",
        icon: "error",
        confirmButtonText: "Delete",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var formData = new FormData();
          formData.append("old_card_id", card.id);
          axios
            .post(process.env.VUE_APP_API_URL + "payment/deleteCard", formData)
            .then((response) => {
              if (response.data.statusCode === 200) {
                this.getData();
                this.$swal(
                  "Deleted!",
                  "Your card has been deleted.",
                  "success"
                );
              }
            })
            .catch((error) => {
              if (error.response.status === 500) {
                this.$swal(
                  "Deleted!",
                  "Somthing wrong please contact your admin.",
                  "Error"
                );
              }
            });
        }
      });
    },
  },
  mounted() {
    // call api on  page load
    this.getData(0);
    this.getPacksData();
    this.$root.$on("autoUpdate", () => {
      this.getData(0);
    });
  },
};
</script>

<template>
  <div class="profile-wrapper billing__page">
    <ul class="nav nav-tabs nav-pills nav-fill " id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active rounded-0"
          id="billing-tab"
          data-toggle="tab"
          href="#billing"
          role="tab"
          aria-controls="billing"
          aria-selected="true"
        >
          <img src="../../assets/images/company-profile-icon.png" alt="" />
          Billing Information
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BillingTop",
};
</script>

<style></style>

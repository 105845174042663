<template>
  <div>
    <div id="mySidebar" class="sidebar" v-if="user">
      <ul class="menu-list">
        <li class="menu-item" v-if ="!isAdmin && !enterpriseAdmin">
          <router-link  to="/registry" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/home.png" alt="" />
            </div>
            <div class="item-name">Registry</div>
          </router-link>
        </li>
        <li class="menu-item" v-if ="isAdmin && enterpriseAdmin">
          <router-link  to="/registries" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/home.png" alt="" />
            </div>
            <div class="item-name">Registry</div>
          </router-link>
        </li>
        <li class="menu-item" v-if="!isAdmin && !enterpriseAdmin && user.company !== null && user.user.secret_answer_status !== null">
          <router-link
            v-if="user.company.nda_status != 1"
            to="/globalnda-signup"
            class="menu-link"
          >
            <div class="item-icon">
              <img src="../../assets/images/pen.png" alt="" />
            </div>
            <div class="item-name">Sign GlobalNDA</div>
          </router-link>
          <a @click="checkNdaStatus" v-else to="/signed-agreements" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/pen.png" alt="" />
            </div>
            <div class="item-name">Sign GlobalNDA</div>
          </a>
        </li>

        <li class="menu-item" v-else-if="!isAdmin && !enterpriseAdmin">
          <a @click="checkNdaStatus" to="/signed-agreements" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/pen.png" alt="" />
            </div>
            <div class="item-name">Sign GlobalNDA</div>
          </a>
        </li>
        <li class="menu-item" v-if="!isAdmin && !enterpriseAdmin && user.agreement != null">
          <router-link to="/signed-agreements" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/file.png" alt="" />
            </div>
            <div class="item-name">My Agreement</div>
          </router-link>
        </li>
        <li class="menu-item" v-else-if="!isAdmin && !enterpriseAdmin ">
          <a @click="checkAgreement" to="/signed-agreements" class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/file.png" alt="" />
            </div>
            <div class="item-name">My Agreement</div>
          </a>
        </li>
        <li class="menu-item" v-show="isAdmin || enterpriseAdmin">
          <router-link to="/users-signed"   class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/user-withouttick.png" alt="" class="usertab"/>
            </div>
            <div class="item-name">Users</div>
          </router-link>
        </li>
        <li class="menu-item" v-show="isAdmin || enterpriseAdmin">
          <router-link to="/Companies"  class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/file.png" alt=""/>
            </div>
            <div class="item-name">Companies</div>
          </router-link>
        </li>
        <li class="menu-item" v-if="isAdmin">
          <router-link to="/enterprise-userlsit"  class="menu-link">
            <div class="item-icon">
              <img src="../../assets//images//file.png" alt=""/>
            </div>
            <div class="item-name">Enterprise User</div>
          </router-link>
        </li>
        <li class="menu-item" v-show="enterpriseAdmin">
          <router-link to="/Enterprise-Companies"  class="menu-link">
            <div class="item-icon">
              <img src="../../assets//images//file.png" alt=""/>
            </div>
            <div class="item-name">Enterprise<br>Companies</div>
          </router-link>
        </li>
        <li class="menu-item" v-show="enterpriseAdmin">
          <router-link to="/templates"  class="menu-link">
            <div class="item-icon">
              <img src="../../assets/images/file.png" alt=""/>
            </div>
            <div class="item-name">Templates</div>
          </router-link>
        </li>
      </ul>
      <div class="closebtn" @click="closeNav()">
        <img src="../../assets/images/Collapse.png" alt="" />
      </div>
      <div id="main" @click="openNav()">
        <img src="../../assets/images/Collapse.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LeftMenu",
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      isAdmin: false,
      isVerfier: false,
      enterpriseAdmin: false,
      User:false

    };
  },
  methods: {
  
    checkNdaStatus() {
      if (this.user.company != null &&  this.user.user.secret_answer_status != null) {
          if (this.user.company.nda_status == 1) {
            this.$swal({
              title: "No new agreements to sign.",
              icon: "info",
              showCancelButton: false,
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({ name: "SignedAgreements" });
              }
            });
          }
      } else if(this.user.company == null) {
        this.$swal({
          title: "Please fill out your company details!",
          icon: "info",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "CompanyProfilePage" });
          }
        });
      }else if( this.user.user.secret_answer_status == null) {
        this.$swal({
          title: "Please fill out user details!",
          icon: "info",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "Profile" });
          }
        });
      }
    },
    checkNdaStatusRegistry() {
      this.$router.push({ name: "Homes" });
    },
    checkAgreement() {
      if (this.user.company == null) {
        this.$swal({
          title: "Please fill out your company details!",
          icon: "info",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "CompanyProfilePage" });
          }
        });
      } else if (this.user.user.secret_answer_status == null) {
        this.$swal({
          title: "Please fill out user details!",
          icon: "info",
          showCancelButton: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "Profile" });
          }
        });
      } else {
        this.$swal({
        text: "Let's sign your first GlobalNDA.",
        iconHtml: '<img src=""">',
        customClass: { icon: "ico" },
        confirmButtonText: "eSign",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: "GlobalNDAForm" });
        }
      });
      }
    },
 
    openNav: function () {
      var x = window.matchMedia("(min-width: 769px)");

      if (x.matches) {
        document.getElementById("mySidebar").setAttribute("style", "width:180px");
        document.getElementById("main").setAttribute("style", "display:none");
        document.querySelector(".closebtn").setAttribute("style", "display:block");
        document
          .querySelector(".registry-wrapper")
          .setAttribute("style", "width:calc(100vw - 180px)");
        document
          .querySelector(".home-wrapper")
          .setAttribute("style", "width:calc(100vw - 180px)");
      }

      var y = window.matchMedia("(max-width: 768px)");

      if (y.matches) {
        document.getElementById("mySidebar").setAttribute("style", "width:50px");
        document.getElementById("main").setAttribute("style", "display:none");
        document.querySelector(".closebtn").setAttribute("style", "display:block");
        document
          .querySelector(".registry-wrapper")
          .setAttribute("style", "width : calc(100vw - 50px)");
        document
          .querySelector(".home-wrapper")
          .setAttribute("style", "width : calc(100vw - 50px)");
      }
    },

    closeNav: function () {
      document.getElementById("mySidebar").setAttribute("style", "width:0");
      document.getElementById("main").setAttribute("style", "display:block");
      document.querySelector(".closebtn").setAttribute("style", "display:none");
      document.querySelector(".registry-wrapper").setAttribute("style", "width:100vw");
      document.querySelector(".home-wrapper").setAttribute("style", "width:100vw");
    },
  },
  mounted() {
    // if(this.user.user.role_id === 1){
    // this.isAdmin=true
    // }
    if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
    } else if (localStorage.getItem("permissions") == 4) {
      this.isVerfier = true;
    } else if (localStorage.getItem("permissions") == 5) {
        this.enterpriseAdmin = true;
    }
    else if (localStorage.getItem("permissions") == 2) {
        this.User= true;
    }
  },
};
</script>

<style scoped>
.usertab {
  height: 17px;
  width: 20px
}
</style>
